import shopifyWarehouseDefaults from '../shopify-warehouse-generic/config.js';

window.Convermax.fitmentFieldsTranslation = {
  Make: 'Marca',
  Model: 'Modello',
  Year: 'Anno',
};

const getFitmentSearchTitle = () => {
  return 'Prodotti per:';
};

export default {
  includes: ['shopify-warehouse-generic'],
  ...shopifyWarehouseDefaults,
  fitmentSearch: {
    ...shopifyWarehouseDefaults.fitmentSearch,
    getFitmentSearchTitle,
  },
  Widgets: [
    ...shopifyWarehouseDefaults.Widgets.filter((w) => !['SearchHeader'].includes(w.name)),
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Migliori risultati',
        'title': 'Nome: A-Z',
        'title:desc': 'Nome: Z-A',
        'price': 'Prezzo: basso-alto',
        'price:desc': 'Prezzo: alto-basso',
      },
    },
  ],
};
